import {
  adminAudjtLogs
} from "../../js/path";
export default {
  data() {
    return {
      showData: false,
      status: false,
      pageNo: null,
      fields: [
        {key:"id", label:"ID"},
        {key:"reference_table_name", label:"Content Type"},
        {key:"reference_table_id", label:"Content ID"},
        {key:"created_by", label:"Created By"},
        {key:"created_at", label:"Creation Date"},
        {key:"action", label:"Action"}
      ],
      params: "",
      tableData: [],
      key: 0,
      currentPage: 1,
      filter: "",
      sortBy: "id",
      sortDesc: true,
      selected: [],
      filterOn:[]
    };
  },
  methods: {
    searchFor() {
      console.log("searchFor")
      this.fetchData("search")
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        // this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      console.log("search")
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData('search');
        }
      } else if (this.filter.length == 0) this.fetchData('search');
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      console.log('Fetching')
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = adminAudjtLogs.fetchAll;
        if (pagination == "search") {
          url = adminAudjtLogs.fetchAll + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
          if(this.filter)
          {
            url = url + "&search=" + this.filter;
          }
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.fetchData(value);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
};